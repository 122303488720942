<!--
 * What?  vue-resize componnet
 * Originally was from this: https://github.com/Akryum/vue-resize
 *
 * But that doesn't work for us any more
 * Why?
 *  - need an esm version (FU webpack!)
 *  - need to NOT mutate the dom in a way that is CSP-incompatible (style-attr inline violation)
 -->

<template>
  <div
    class="resize-observer"
    tabindex="-1"
  />
</template>

<script>

export default {
  name: 'ResizeObserver',

  props: {
    emitOnMount: {
      type: Boolean,
      default: false
    },

    ignoreWidth: {
      type: Boolean,
      default: false
    },

    ignoreHeight: {
      type: Boolean,
      default: false
    }
  },
  emits: ['notify'],

  mounted () {
    this.$nextTick(() => {
      this._w = this.$el.offsetWidth
      this._h = this.$el.offsetHeight
      if (this.emitOnMount) {
        this.emitSize()
      }
    })

    this.$el.__resizeObserver = new ResizeObserver((_entries) => {
      this.compareAndNotify()
    })
    this.$el.__resizeObserver.observe(this.$el)
  },

  beforeUnmount () {
    if (!!this.$el && !!this.$el.__resizeObserver) {
      this.$el.__resizeObserver.unobserve(this.$el)
    }
  },

  methods: {
    compareAndNotify () {
      if ((!this.ignoreWidth && this._w !== this.$el.offsetWidth) || (!this.ignoreHeight && this._h !== this.$el.offsetHeight)) {
        this._w = this.$el.offsetWidth
        this._h = this.$el.offsetHeight
        this.emitSize()
      }
    },

    emitSize () {
      this.$emit('notify', {
        width: this._w,
        height: this._h
      })
    }
  }
}
</script>

<style scoped>
.resize-observer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  pointer-events: none;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.resize-observer :deep(object) {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
</style>
